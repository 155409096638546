import React, { useEffect, useState } from 'react'


import NavBar from '../components/navbar/NavBar'
import Footer from '../components/footer/Footer'

import R1 from '../assets/roadmap/1.png'
import R2 from '../assets/roadmap/2.png'
import R3 from '../assets/roadmap/3.png'
import R4 from '../assets/roadmap/4.png'
import R5 from '../assets/roadmap/5.png'


const Home = () => {
    return (
        <>


            <NavBar />

            <div className="main-road-map-cont">
                <div className="container">
                    <div className="road-items">
                        <div className="road">
                            <img src={R1} alt="" />
                        </div>
                        <div className="road">
                            <img src={R2} alt="" />
                        </div>
                        <div className="road">
                            <img src={R3} alt="" />
                        </div>
                        <div className="road">
                            <img src={R4} alt="" />
                        </div>
                        <div className="road">
                            <img src={R5} alt="" />
                        </div>
                    </div>
                </div>

            </div>

            <Footer />


        </>
    )
}

export default Home
